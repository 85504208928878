@import "./../../Global.scss";

.contact-us-container {
    background-color: #F5F5F5;
    background-image: url(../../assets/images/ContactUsBackground.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #424242;
    position: relative;
    padding: 4.5rem 0;
    margin: 0 auto;
    text-align: center;
    
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #ffffffc7;
        opacity: 0.6;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        z-index: 0;
    }

    .content {
        position: relative;
        margin: 20px auto 50px;
        padding: 12px 12px 12px 12px;
        background-color: #FFFFFFD6;
        border-radius: 25px 25px 25px 25px;
        max-width: 800px;
        z-index: 1;

        h2 {
            color: $primary-color;
            font-size: 2.5rem;
        }
    }

    a {
        position: relative;
        font-family: "Montserrat", Sans-serif;
        font-size: 22px;
        font-weight: 500;
        background-color: #14d2e8;
        color: #ffffff;
        padding: 12px 24px;
        max-width: 320px;
        text-transform: uppercase;
        transition: 0.5s;
        text-decoration: none;
        border-radius: 0.5rem;
        z-index: 1;
            
        &:hover {
            transform: scale(1.2);
            color: $primary-color;
            background-color: #fff;
        }
    }
}

@media (max-width: 1024px) {
    .contact-us-container {
        a {
            font-size: 16px;
        }
    }
}