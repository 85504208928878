@import './../../Global.scss';


.card {
    border-radius: 0.6rem;

    .card-body {
        text-align: left;
        height: 42rem;
        min-height: 43rem;

        .card-icon {
            background-color: $primary-color;
            border-radius: 50%;
            font-size: 50px;
            color: #ffffff;
            width: 6rem;
            height: 6rem;
            padding: 0.8rem;
            margin: 2rem auto 0;
            text-align: center;
                
            img {
                margin: 5px auto 0;
                width: 4rem;
                height: 4rem;
            }   
        }
    }
    
    h3 {
        color: $primary-color !important;
        margin: 2rem auto;
        text-align: center;
    }
    
    p {
        color: $primary-text; 
        line-height: 2.3rem;
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .card {
        .card-body {
            height: 42rem;
            min-height: 50rem;
        }
    }
}

@media (max-width: 991px) {
    .card {
        margin-bottom: 2rem;

        .card-body {
            height: auto;
            min-height: inherit;

            p {
                margin-bottom: 1rem;
            }
        }
    }
}