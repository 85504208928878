@import "./../Global.scss";

.header {
    .header-top {
        background-color: #011C38;
        padding: 0.8rem;

        .content {
            a {
                font-family: "Montserrat", Sans-serif;
                font-size: 1rem;
                color: #ffffff;
                padding: 0.8rem;
                max-width: 320px;
                transition: 0.5s;
                text-decoration: none;
                    
                &:hover {
                    transform: scale(1.2);
                    color: $primary-color;
                }

                svg {
                    color: #3194BE;
                    margin-right: 1rem;
                }
            }           

            .top-right {
                float: right;
                
                a {
                    font-size: 1.2rem;
                    padding: 1rem;
                    text-align: center;
                    
                    svg {
                        color: #fff;
                        margin: 0 auto;
                    }

                    &:hover {
                        background-color: #3194BE;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .header-top {
            .content {
                .col {
                    text-align: center;
                    &:nth-last-child(1) {
                        display: none;
                    }
                }

                a {
                    max-width: 100%;
                    padding: 0.5rem;
                    font-size: 0.8rem;

                    svg {
                        margin-right: 0.2rem;
                    }
                }
            }
        }
    }   

    .header-menu {
        border-bottom: #3194BE solid 5px;

        .logo {
            margin-top: 10px;

            img {
                width: 100px;
            }
        }

        .main-nav {
            ul {
                padding: 0;
                margin: 0 auto;

                li {
                    display: inline-block;
                    
                    a {
                        color: #011C38;
                        display: inline-block;
                        padding: 28px 50px;
                        text-decoration: none;
                        
                        &.active {
                            color: #3194BE;
                            border-bottom: #3194BE solid 5px;
                        }
                    }
                }
            }
        }
    }
}

