@import "./../Global.scss";

.home {
    .home-banner {
        background-color: #FFFFFF;
        background-image: url("./../assets/images/biji_plastik_siu_03_r1_s.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color: #fff;
        position: relative;
        padding: 6rem 0;
        
        &::after {
            content: '';
            position: absolute;
            background-image: url("./../assets/images/gradient-for-hero-image-r1.png");
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.8;
            transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;

            h1 {
                font-size: 2.5rem;
                margin-bottom: 3.5rem;

                span {
                    text-decoration: underline;
                    margin-right: 0.5rem;
                    width: 100%;
                    max-width: 245px;
                }

                .rotating-word {
                    display: inline-block;
                    animation: rotating 3s infinite;
                    
                    &.active {
                        display: inline-block;
                    }

                    &.inactive {
                        display: none;
                    }

                    @keyframes rotating {
                        0% {
                            opacity: 1;
                            transform: translateZ(30)
                        }
        
                        to {
                            opacity: 0;
                            transform: translateZ(100px)
                        }
                    }
                    
                    @keyframes rotating {
                        0% {
                            opacity: 0;
                            transform: translateY(-20%)
                        }
        
                        60% {
                            opacity: 1;
                            transform: translateY(0%)
                        }
        
                        to {
                            opacity: 1;
                            transform: translateY(20)
                        }
                    }
                }
            }

            h3 {
                font-size: 1.3rem;
                margin-bottom: 4rem;
            }

            a {
                position: relative;
                font-family: "Montserrat", Sans-serif;
                font-size: 18px;
                font-weight: 500;
                background-color: #14d2e8;
                color: #ffffff;
                padding: 12px 24px;
                max-width: 250px;
                text-transform: uppercase;
                transition: 0.5s;
                text-decoration: none;
                border-radius: 0.5rem;
                z-index: 1;
                    
                &:hover {
                    transform: scale(1.2);
                    color: $primary-color;
                    background-color: #fff;
                }
            }
        }

    }

    .home-content-1 {
        background-color: #FFFFFF;
        padding: 6rem 0;

        .content {
            h2 {
                color: #6EC1E4;
                font-size: 2.5rem;
                margin-bottom: 4rem;
            }

            .col-md-4 {
                padding: 0;
            }

            @media (max-width: 767px) {
                .col-md-4 {
                    padding: 0 0.8rem;
                }
            }

            h4 {
                color: $primary-color;
                font-size: 1.3rem;
                margin: 1rem 0;
            }

            p {
                font-size: 1.2rem;
                margin: 3rem auto 1.5rem;
                vertical-align: baseline;
                line-height: 2;
            }
        }

        .image-container {
            position: relative;
            width: 100%;
            height: auto;
            perspective: 1000px;
          }
          
          .image-container img {
            width: 100%;
            height: 100%;
            transition: transform 0.5s;
          }
          
          .flipped img {
            transform: rotateX(180deg);
          }
          
    }

    .home-content-2 {
        background-color: #FFFFFF;
        background-image: url("./../assets/images/biji_plastik_siu_10_s2x.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #424242;
        position: relative;
        padding: 6rem 0;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #FFFFFF;
            opacity: 0.88;
            transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
            z-index: 0;
        }
        
        .content {
            position: relative;
            z-index: 1;

            h2 {
                color: $primary-color;
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }
           
            .content-box {
                border-bottom: 1px solid #BDBDBD;
                margin-bottom: 1.5rem;
            
                &:last-child {
                    border-bottom: none;
                }
            
                h3 {
                    color: $primary-color;
                    font-size: 1.8rem;
                    margin-bottom: 1rem;
                }
            }
                       

            p {
                font-size: 1.2rem;
                margin-bottom: 1.5rem;
            }

            iframe {
                width: 560;
                height: 315;
            }
        }
    }

    @media (max-width: 1024px) {
        .home-banner {
            .content {
                font-size: 16px;
                
                .col {
                    &:nth-last-child(1) {
                        display: none;
                    }
                }
            }
        }
    }
}