@import './../Global.scss';

.features-container {
    background-color: $primary-color;
    color: #ffffff;
    padding: 100px 0;

    h2 {
        font-size: 2.8rem;
        margin-bottom: 2rem;
        font-weight: 600;
    }
    
    p {
        font-size: 1.2rem;
        margin-bottom: 7rem;
    }
}