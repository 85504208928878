@import "../Global.scss";

@import "../Global.scss";

:root {
  --animation-duration: 1200ms;
}

.footer {
    background-color: #021c38;
    color: #FFFFFFA6;
    padding-top: 50px;

    .footerCol3,
    .footerCol2,
    .footerCol1 {
        line-height: 2;

        .footer-logo {
            margin-bottom: 1rem;

            img {
                width: 120px;
            }
        }

        h2 {
            color: $primary-color;
            font-weight: 400;
            text-align: justify;
            margin-bottom: 1.5rem;
            position: relative;
            
            span {
                position: relative;
                display: block;
                font-weight: bold;
            }

            .footerTitLine {
             &::after {
                content: "";
                position: absolute;
                background-color: #021c38;
                opacity: 0;
                width: 100%;
                height: 15px;
                bottom: 0;
                left: 0;
                z-index: 2;
                transform: translateX(-100%);
                animation: imgAnimation 10s infinite;
            }
                img {
                    width: 170px;
                }
            }
            
            @keyframes imgAnimation {
                0% {
                    opacity: 1;
                    transform: translateX(-100%);
                }
                25% {
                    opacity: 1;
                    transform: translateX(0);
                }
                100% {
                    opacity: 0;
                    transform: translateX(0);
                }
            }
              
        }

        h3 {
            color: $primary-color;
            font-weight: 400;
            text-align: center;
            margin-bottom: 1.5rem;
        }
        
        p {
            margin-bottom: 2rem;
        }

        .footer-btn {
            a {
                position: relative;
                display: block;
                font-family: "Montserrat", Sans-serif;
                font-weight: 600;
                background-color: #3194BE;
                color: #ffffff;
                padding: 12px 24px;
                width: 100%;
                transition: 0.5s;
                text-align: center;
                text-decoration: none;
                border-radius: 0.5rem;
                    
                &:hover {
                    transform: scale(1.1);
                    background-color: #14D2E8;
                    color: #fff;
                }

                svg {
                    float: left;
                    margin-top: 5px;
                }
            }
        }

        .company-detail {
            margin-top: 2rem;

            .brand-icon {
                text-align: center;
                
                .icon-brand-container {
                    display: inline-block;
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 8px;
                    padding-top: 4px;

                    &.facebook-bg-color {
                        background-color: #3b5998;
                    }
                
                    &.instagram-bg-color {
                        background-color: #262626;
                    }
                
                    &.youtube-bg-color {
                        background-color: #cd201f;
                    }
                }
            }
        }

        .title-icon {
            position: relative;
            font-size: 1.2rem;
            border-bottom: rgba(255, 255, 255, 0.6509803922) solid 2px;
            margin: 2rem auto;
            width: 70%;
        
            svg {
                content: '';
                position: absolute;
                width: 26px;
                height: 26px;
                top: -16px;
                left: 50%;
                z-index: 1;
                margin-left: -38px;
                padding: 0px 20px;
                background-color: #021c38;
            }
        }

        .card {
            border-radius: 0px;
            border: none;
            box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);

            p {
                line-height: 1.3;
            }

            .card-img {
                background-image: url(../assets/images/Plastik-PP-Dalam-Bentuk-Butiran-S.jpg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                color: #424242;
                position: relative;
                padding-top: 9rem;
                margin: 0 auto;
                text-align: center;
                border-radius: 0px;
            }
           
            &:nth-child(4) {
                .card-img {
                    background-image: url(../assets/images/Biji-Plastik-Original-dan-Daur-Ulang-S.jpg);
                }
            }
           
            &:nth-child(5) {
                .card-img {
                    background-image: url(../assets/images/Plastik-PP-Surya-Indo-Utama-E-S.jpg);
                }
            }

            .card-body {
                position: relative;
                height: auto;
                min-height: 1rem;
                padding-top: 2.7rem;
                background-color: #ffffff;

                .card-title {
                    position: absolute;
                    background-color: #3194be;
                    color: #fff;
                    top: -40px;
                    left: 50%;
                    border-radius: 50%;
                    margin-left: -38.51px;
                    padding: 20px 25px;
                    font-size: 14px;
                    text-align: center;
                }

                a {
                    span {
                        display: inline-block;
                        margin-right: 0.3rem;
                    }
                }

                .btn-primary {
                    background-color: #14d2e8;
                    border: none;
                }
            }
        }

        .table {
            tr {
                .color-style {
                    color: #f00;
                }
            }
        }
    }

    .copy-right {
        color: #ADADAD;
        border-top: rgba(255, 255, 255, 0.19) solid 2px;
        text-align: center;
        display: block;
        padding: 20px;
        margin-top: 50px;
    }

    @media (max-width: 1024px) {
        .col-lg-4 {
            margin-bottom: 3rem;
        }
    }
    
    @media (max-width: 991px) {
        .card {
            margin-bottom: 0.7rem;
        }
    }
    
}